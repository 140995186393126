<template>
  <div>
    <!-- Render the chart component -->
    <apex-chart
      type="area"
      height="220"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
// Import node packages
import ApexChart from 'vue-apexcharts'
import merge from 'lodash/merge'

// Import default configuration object
import apexChartBaseOptions from '@/json/apexChartBaseOptions.js'

// Export the SFC
export default {
  // Name of the SFC
  name: "DayWise",

  // Register the components
  components: {
    ApexChart
  },

  // Accept incoming data from parent
  props: {
    series: {
      type: Array,
      required: true
    },

    annotations: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  // Get computable readonly variables
  computed: {
    /**
     * Get the chart configurations object
     *
     * @returns {object}
     */
    chartOptions() {
      return merge(apexChartBaseOptions(), {
        annotations: this.annotations,
        chart: {
          offsetX: 0,
        },
        grid: {
          padding: {
            top: this.annotations.length > 0 ? 20 : 5,
            right: 20,
            bottom: 5,
            left: 20,
          },
        },
        xaxis: {
          type: "datetime",
          min: this.series[0].data[0][0],
          max: this.series[0].data[this.series[0].data.length - 1][0],
          title: {
            text: "Date",
          },
        },
        yaxis: {
          labels: {
            formatter: (seriesName) =>
              nFormatter(seriesName).toLocaleUpperCase(),
          },
          type: "numeric",
          title: {
            text: "Posts",
          },
        },
        stroke: {
          width: 4,
        },
        "colors": [
          "#f05d56",
          "#1890FF",
        ],
        fill: {
          opacity: 5,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (y) => {
              if (typeof y !== "undefined") {
                return `${nFormatter(y.toFixed(0))}`;
              }
              return y;
            },
          },
        },
      })
    }
  }
}
</script>
